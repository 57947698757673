import { Injectable } from '@angular/core';
import { currentUser } from '@app/core/selectors/auth.selector';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

console.log('comecou')

if (environment.base_url.includes('-dev') || environment.base_url.includes('localhost')) {
  document.documentElement.style.setProperty('--env-login-color', '#6b6d7a');
  document.documentElement.style.setProperty('--env-menu-color', '#7691a1');
  document.documentElement.style.setProperty('--env-header-color', '#8a9c9e');
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Menus',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: 'feather icon-home',
        breadcrumbs: false
      },
      {
        id: 'cadastros',
        title: 'Cadastros',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'cliente',
            title: 'Clientes',
            type: 'item',
            url: '/cadastros/cliente',
            target: false
          },
          {
            id: 'fornecedor',
            title: 'Fornecedores',
            type: 'item',
            url: '/cadastros/fornecedor',
            target: false
          },
          {
            id: 'painel',
            title: 'Produto Painel',
            type: 'item',
            url: '/cadastros/painel',
            target: false
          },
          {
            id: 'transportadora',
            title: 'Transportadoras',
            type: 'item',
            url: '/cadastros/transportadora',
            target: false
          },
          {
            id: 'emitentes',
            title: 'Emitentes',
            type: 'item',
            url: '/emitentes',
            target: false
          },
          {
            id: 'produtos',
            title: 'Produtos',
            type: 'item',
            url: '/cadastros/estoque',
            target: false
          },
          {
            id: 'finances',
            title: 'Formas de Pagamento',
            type: 'item',
            url: '/finances/payment-forms',
            target: false
          },
          {
            id: 'usuarios',
            title: 'Usuários',
            type: 'item',
            url: '/users',
            target: false
          }
        ]
      },
      {
        id: 'report',
        title: 'Relatórios',
        type: 'collapse',
        icon: 'fas fa-chart-pie',
        children: [
          {
            id: 'report_produtos',
            title: 'Produtos',
            type: 'item',
            url: '/report/operacoes',
            target: false
          },
          {
            id: 'report_bancarizacao_amp',
            title: 'Bancarização',
            type: 'item',
            url: '/bancarizacao',
            target: false
          },
          {
            id: 'report_vendas',
            title: 'Vendas',
            type: 'item',
            url: '/report/vendas',
            target: false
          },
          {
            id: 'report_caixa',
            title: 'Caixa',
            type: 'item',
            url: '/report/caixa',
            target: false
          },
          {
            id: 'report_receber',
            title: 'Contas Receber',
            type: 'item',
            url: '/report/contas-receber',
            target: false
          },
          {
            id: 'report_pagar',
            title: 'Contas Pagar',
            type: 'item',
            url: '/report/contas-pagar',
            target: false
          },
        ]
      }
    ]
  }
];

@Injectable({
  providedIn: 'root'
})
export class NavigationItem {

  permissions: any = {};
  master: number = 0;
  username: string = '';
  NavigationItems: any[] = []

  constructor(store: Store) {
    store.pipe(select(currentUser)).subscribe(user => {
      if (user) {
        this.username = user.email;
        this.master = user?.master?? 0;
        this.permissions = user.permissions;
        this.generateMenu();
      }
    })
  }

  public get() {
    return this.NavigationItems;
  }

  generateMenu() {
    // item
    let dashboardItem: any = {};
    let cadastroItem: any[] = [];
    let inadimplenciaItem: any[] = [];
    let remesasItem: any[] = [];
    let campanhaItem: any[] = [];
    let relatorioItem: any[] = [];

    // collapse
    let collapse: any[] = [];

    // collapse
    let group: any[] = [];

    let permissionsDesc: string = this.permissions.description;
    let categoryDesc: string ='';

    if(this.permissions?.description.startsWith('Operação') || this.permissions?.description.startsWith('Oper. ') || this.permissions?.description.startsWith('Filial')){
      categoryDesc = 'Filial';
      permissionsDesc = 'Operação';
    }
    if(this.permissions?.description.startsWith('Admin. ')){
      categoryDesc = 'Matriz';
      permissionsDesc = 'Operação';
    }
    // dashboardItem =  { id: 'dashboard', title: 'Dashboard', type: 'item', url: '/dashboard', target: false };

    // set item CadastroItem
    if (['Administrador', 'Comercial'].includes(permissionsDesc)) {
      cadastroItem.push({ id: 'cliente', title: 'Clientes', type: 'item', url: '/cadastros/cliente', target: false });
    }

    if (['Administrador'].includes(permissionsDesc)) {
      cadastroItem.push({ id: 'painel', title: 'Produto Painel', type: 'item', url: '/cadastros/painel', target: false });
      cadastroItem.push({ id: 'grupo', title: 'Comercial', type: 'item', url: '/cadastros/comercial', target: false });
      cadastroItem.push({ id: 'grupo', title: 'Bônus - Grupos', type: 'item', url: '/cadastros/grupo', target: false });
      cadastroItem.push({ id: 'grupo', title: 'Kits - Contrato', type: 'item', url: '/cadastros/kits', target: false });
      cadastroItem.push({ id: 'bonus', title: 'Bônus Correspondente', type: 'item', url: '/cadastros/bonus', target: false });
    }

    // Insere o cadastro de usuários
    if (this.master === 1 || categoryDesc == 'Matriz') {
      cadastroItem.push({ id: 'usuarios', title: 'Usuários', type: 'item', url: '/users', target: false });
    }

    if (['Comercial', 'Operação', 'Administrador'].includes(permissionsDesc)) {
      cadastroItem.push({ id: 'cad_oper', title: 'Operação', type: 'item', url: '/operacoes', target: false });
      cadastroItem.push({ id: 'cad_bancarizacao', title: 'Operação - Bancarização', type: 'item', url: '/operacao/tipo/1', target: false });
      cadastroItem.push({ id: 'lote', title: 'Operação - Lotes', type: 'item', url: '/cadastros/lotes', target: false });
    }

    if (['Comercial', 'Administrador'].includes(permissionsDesc) || categoryDesc == 'Matriz') {
      cadastroItem.push({ id: 'cad_prod', title: 'Produtos - Bancarização', type: 'item', url: '/produtos', target: false });
      cadastroItem.push({ id: 'cad_tabe', title: 'Tabelas', type: 'item', url: '/tabelas', target: false });
    }

    // set item inadimplenciaItem
    if (['Inadimplência', 'Administrador'].includes(permissionsDesc)) {
      inadimplenciaItem.push({ id: 'inad_spc', title: 'Negativação - SPC', type: 'item', url: '/inadimplencia/negativacao/spc', target: false });
      inadimplenciaItem.push({ id: 'inad_pub', title: 'Convênios - Consig. Pub.', type: 'item', url: '/inadimplencia/consignado/publico', target: false });
      inadimplenciaItem.push({ id: 'inad_prv', title: 'Convênios - Consig. Priv.', type: 'item', url: '/inadimplencia/consignado/privado', target: false });
    }

    // set item remesasItem
    if (['Remessas', 'Administrador'].includes(permissionsDesc)) {
      remesasItem.push({ id: 'reme_cart', title: 'Remessas - Cartão', type: 'item', url: '/remessa/cartao', target: false });
      remesasItem.push({ id: 'reme_baix', title: 'Liquidações', type: 'item', url: '/remessa/baixas', target: false });
    }

    // set item relatorioItem
    if (['Comercial', 'Administrador'].includes(permissionsDesc)) {
      if (['Administrador'].includes(permissionsDesc)) {
        relatorioItem.push({ id: 'report_produtos', title: 'Operações - Grupo', type: 'item', url: '/report/operacoes/grupo', target: false });
      }
      if (['Comercial', 'Administrador'].includes(permissionsDesc)) {
        relatorioItem.push({ id: 'report_produtos', title: 'Operações - Comercial', type: 'item', url: '/report/operacoes/comercial', target: false });
      }
      relatorioItem.push({ id: 'report_bancarizacao_amp', title: 'Operações - Bancarização', type: 'item', url: '/report/bancarizacao', target: false });

      if (this.username === 'otavio') {
        relatorioItem.push({ id: 'report_conferencia', title: 'Pagamentos - Conferência', type: 'item', url: '/report/operacoes/conferencia', target: false });
      }
    }

    if (['Comercial', 'Operação', 'Administrador'].includes(permissionsDesc)) {
      relatorioItem.push({ id: 'report_bancarizacao', title: 'Relatório de Produção', type: 'item', url: '/report/operacoes/bancarizacao', target: false });
      relatorioItem.push({ id: 'report_comissoes', title: 'Relatório de Comissão', type: 'item', url: '/report/operacoes/comissao', target: false });
      relatorioItem.push({ id: 'report_dataprev', title: 'Dataprev - Consumo', type: 'item', url: '/report/dataprev', target: false });
      campanhaItem.push({ id: 'campanha_operacoes', title: 'Visualizar Campanhas', type: 'item', url: '/report/operacoes/campanhas', target: false });
    }

    if (['Administrador'].includes(permissionsDesc)) {
      relatorioItem.push({ id: 'report_clientes', title: 'Cadastro - Clientes', type: 'item', url: '/report/operacoes/clientes', target: false });
      relatorioItem.push({ id: 'report_convenios', title: 'Cadastro - Convênios', type: 'item', url: '/report/operacoes/convenios', target: false });
      relatorioItem.push({ id: 'report_correspondentes', title: 'Cadastro - Correspondente', type: 'item', url: '/report/operacoes/correspondentes', target: false });
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------------
    // set collapse Cadastros
    if (cadastroItem.length > 0) {
      collapse.push({ id: 'cadastros', title: 'Cadastros', type: 'collapse', icon: 'feather icon-layout', children: cadastroItem });
    }
    // set collapse Inadimplência
    if (inadimplenciaItem.length > 0) {
      collapse.push({ id: 'inadimplencia', title: 'Inadimplência', type: 'collapse', icon: 'feather icon-user-x', children: inadimplenciaItem });
    }
    // set collapse Remessas
    if (remesasItem.length > 0) {
      collapse.push({ id: 'remessas', title: 'Remessas', type: 'collapse', icon: 'fas fa-external-link-square-alt', children: remesasItem });
    }
    // set collapse Relatórios
    if (campanhaItem.length > 0) {
      collapse.push({ id: 'campanha', title: 'Campanhas', type: 'collapse', icon: 'feather icon-trending-up', children: campanhaItem });
    }
    // set collapse Relatórios
    if (relatorioItem.length > 0) {
      collapse.push({ id: 'report', title: 'Relatórios', type: 'collapse', icon: 'fas fa-chart-pie', children: relatorioItem });
    }

    if (collapse) {
      this.NavigationItems.push({
        id: 'navigation',
        title: 'Menu',
        type: 'group',
        icon: 'feather icon-monitor',
        children: collapse
      });
    }
  }
}
